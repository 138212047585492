import React, { useState } from 'react'
import './Checkbox.scss'
import { useField } from "formik";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setConsultation } from "../../reducers/formReducer";

interface ICheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    id: string
}

const Checkbox: React.FC<ICheckbox> = (props) => {
    const { name, children, id } = props
    const { images } = useSelector((state: RootState) => state.images)
    const [field, meta, helpers] = useField(name)
    const [isChecked, setIsChecked] = useState<boolean>(field.value)
    const dispatch = useDispatch()

    const handleClick: React.MouseEventHandler<HTMLLabelElement> = (event) => {
        event.preventDefault()
        const flag = !isChecked
        dispatch(setConsultation({ id: id, consultation: flag }))
        helpers.setValue(flag)
        setIsChecked(flag)
    }

    return (
        <label
            onClick={handleClick}
            className={"checkbox"}
        >
            <span className={`checkbox__box checkbox__box--${isChecked ? "checked" : "unchecked"}`}>
                {isChecked && <img src={images["icon-correct-checkbox"]} alt="" />}
            </span>
            <span className={"checkbox__text"}>{children}</span>
            <input
                tabIndex={2}
                type="checkbox"
                name={name}
                checked={isChecked}
                readOnly={true}
            />
        </label>
    )
}

export default Checkbox