import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAnswerFields {
    text: string
    consultation: boolean
    topic: number
}

interface IAnswer {
    form: {
        [id: string]: IAnswerFields
    }
}

const initialState: IAnswer = {
    form: {}
}

export const formReducer = createSlice({
    name: "form",
    initialState,
    reducers: {
        setInitials(state, action: PayloadAction<any>) {
            let obj: any = {}
            action.payload.forEach((expert: any) => {
                obj[String(expert?.id)] = {
                    text: "",
                    consultation: false,
                    topic: 0
                }
            })

            state.form = obj
        },
        setQuestionText(state, action: PayloadAction<{ id: string, text: string }>) {
            state.form[action.payload.id].text = action.payload.text;
        },
        setConsultation(state, action: PayloadAction<{ id: string, consultation: boolean }>) {
            state.form[action.payload.id].consultation = action.payload.consultation
        },
        setTopic(state, action: PayloadAction<{ id: string, topic: number }>) {
            state.form[action.payload.id].topic = action.payload.topic
        },
        setInitial(state, action: PayloadAction<{ id: string }>) {
            state.form[action.payload.id] = {
                text: "",
                consultation: false,
                topic: 0
            }
        }
    },
})

export const {
    setInitials,
    setQuestionText,
    setConsultation,
    setTopic,
    setInitial
} = formReducer.actions;

export default formReducer.reducer;
