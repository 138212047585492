import React from 'react'
import { ColorType } from "../../types";
import './IconWithRound.scss'

interface IIconWithRound extends React.HTMLAttributes<HTMLDivElement> {
    color: ColorType
    image: React.ReactNode
}

const IconWithRound: React.FC<IIconWithRound> = (props) => {
    const { color, className, image, onClick } = props

    const css: Array<string | undefined> = ['icon-with-round']
    css.push(className)
    css.push(color)

    return (
        <div className={css.filter(Boolean).join(" ")} onClick={onClick}>
            {image}
            {/*<Icon name={ICONS[name]} width={24} height={24} />*/}
        </div>
    )
}

export default IconWithRound