import React, { useState } from 'react'
import './Faq.scss'
import Accordion from "../../components/Accordion/Accordion";
import { useQuery } from "@tanstack/react-query";
import { n8nProvider } from "../../api";
import { ArticleType } from "../../types";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { useNavigate } from "react-router-dom";
import Layout from "../../hoc/Layout/Layout";
import { useDispatch } from "react-redux";
import { setIsPageLoading } from "../../reducers/loaderReducer";
import P from "../../components/P/P";
import { runTypograph } from "../../helpers";

const Faq = () => {
    const [current, setCurrent] = useState<number>(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { data, isSuccess, isLoading } = useQuery<ArticleType[], Error>({
        queryKey: ["faq"],
        queryFn: async () => {
            const response = await n8nProvider.get("?action=faq-texts")
            return response.data[0].data
        },
    })

    if (isLoading) {
        dispatch(setIsPageLoading(true))
    }

    if (isSuccess) {
        dispatch(setIsPageLoading(false))
    }

    const handleClick = (index: number) => {
        setCurrent(index)
    }

    return (
        <Layout
            containerVariant={"without-button"}
        >
            <BackButton onClick={() => {
                navigate(-1)
            }} />
            <div className={"faq"}>
                {data?.map((item: ArticleType, index) => {
                    return (
                        <Accordion
                            key={index}
                            title={runTypograph(item?.attributes?.title)}
                            onClick={() => {
                                handleClick(index)
                            }}
                            isOpen={index === current}
                        >
                            <P
                                color={"weakWhite"}
                            >
                                {runTypograph(item?.attributes?.text)}
                            </P>
                        </Accordion>
                    )
                })}
            </div>
        </Layout>
    )
}

export default Faq