import React from 'react'
import './Heading.scss'

type THeadingSize = 1 | 2 | 3 | 4 | 5 | 6

interface IHeading extends React.HTMLAttributes<HTMLDivElement> {
    size: THeadingSize,
    children: React.ReactNode | string
    color?: "white" | "black"
    align?: "left" | "center" | "right"
}

const Heading: React.FC<IHeading> = (props) => {
    const {
        size,
        children,
        color = "black",
        align = "left",
        className
    } = props

    const tag = `h${size}`
    const css: Array<string | undefined> = [tag, `h${size}--${color}`, `h${size}--${align}`]
    css.push(className)

    return React.createElement(tag, { className: css.filter(Boolean).join(" ") }, children)
}

export default Heading