import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IImages {
    images: {
        [key: string]: string
    }
}

const initialState: IImages = {
    images: {}
};

export const imagesReducer = createSlice({
    name: "images",
    initialState,
    reducers: {
        setImages(state, action: PayloadAction<Record<string, string>>) {
            state.images[action.payload.name] = action.payload.image
        }
    },
});

export const {
    setImages
} = imagesReducer.actions;

export default imagesReducer.reducer;
