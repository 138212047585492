import React from 'react'
import './MyQuestions.scss'
import Layout from "../../hoc/Layout/Layout";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import VFlex from "../../components/VFlex/VFlex";
import Card from "../../components/Card/Card";
import HFlex from "../../components/HFlex/HFlex";
import ExpertCard from "../../components/ExpertCard/ExpertCard";
import ChipField from "../../components/ChipField/ChipField";
import P from "../../components/P/P";
import Button, { IButton } from "../../components/Button/Button";
import { formatDate, runTypograph } from "../../helpers";
import Heading from "../../components/Heading/Heading";
import _ from 'lodash'

const MyQuestions = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state: RootState) => state.user)
    const { images } = useSelector((state: RootState) => state.images)

    const question = user?.attributes?.questions?.data

    const expertsIds: Array<number> = _.uniq(question?.map((question: any) => {
        return question?.attributes?.expert?.data?.id
    }))

    const buttons: Array<IButton | null> = [
        question?.length === 0 ?
            {

                children: "Выбрать эксперта",
                variant:
                    "secondary",
                onClick:
                    () => navigate("/experts")
            } : null
    ]

    return (
        <Layout
            buttons={buttons.filter(Boolean) as IButton []}
            containerVariant={question?.length === 0 ? "with-button" : "without-button"}
        >
            <BackButton
                onClick={() => {
                    navigate('/')
                }}
            />
            <div className="my-questions">
                {question?.length ?
                    <VFlex>
                        {
                            expertsIds?.map((expertId: number, index: number) => {
                                const questions = question?.filter((question: any) => {
                                    return question?.attributes?.expert?.data?.id === expertId
                                })

                                const day = 1000 * 60 * 60 * 24
                                const isAvailableToAsk = new Date(questions?.[0]?.attributes?.expert?.data?.attributes?.liveDate).getDate() - new Date(day).getDate() > 0
                                const expert = questions?.[0]?.attributes?.expert?.data

                                return (
                                    <Card
                                        key={index}
                                        color={"grey"}
                                    >
                                        <VFlex>
                                            <HFlex>
                                                <ExpertCard expert={expert} />
                                                <ChipField
                                                    color={"purple"}
                                                    className={"my-questions__chipfield"}
                                                >
                                                    <P variant={"int-2"}>{formatDate(expert?.attributes?.liveDate)}</P>
                                                </ChipField>
                                            </HFlex>
                                            {
                                                questions?.map((question: any, index: number) => {
                                                        return (
                                                            <Card
                                                                className={"my-questions__card"}
                                                                color={"black"}
                                                                key={index}
                                                            >
                                                                <VFlex>
                                                                    <HFlex>
                                                                        <ChipField
                                                                            className={"my-questions--index"}
                                                                            color={"pink"}
                                                                        >
                                                                            {index + 1}
                                                                        </ChipField>
                                                                        <ChipField
                                                                            color={"weakWhite"}
                                                                        >
                                                                            <P variant={"int-2"}
                                                                               color={"black"}
                                                                            >
                                                                                {question?.attributes?.topic}
                                                                            </P>
                                                                        </ChipField>
                                                                    </HFlex>
                                                                    <P
                                                                        className={"my-questions__p"}
                                                                        variant={"int-2"}
                                                                        color={"white"}
                                                                    >
                                                                        {runTypograph(question?.attributes?.question_text)}
                                                                    </P>
                                                                </VFlex>
                                                            </Card>
                                                        )
                                                    }
                                                )
                                            }
                                            {questions?.length < 3
                                                && isAvailableToAsk
                                                &&
                                                <Button
                                                    variant={"secondary"}
                                                    onClick={() => navigate(`/ask-question/${expert?.id}`)}
                                                >
                                                    Задать еще вопрос
                                                </Button>
                                            }
                                        </VFlex>
                                    </Card>
                                )
                            })

                        }
                    </VFlex>
                    :
                    <div className={"no-questions"}>
                        <img
                            src={images['no-question']}
                            alt=""
                        />
                        <Heading
                            size={3}
                            color={"white"}
                            align={"center"}
                        >
                            Похоже, вы еще не задали вопросы. Пора это исправить
                        </Heading>
                    </div>
                }
            </div>
        </Layout>
    )
}

export default MyQuestions