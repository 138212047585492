// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hflex {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  grid-gap: 0.5rem;
}
.hflex--jcsb {
  justify-content: space-between;
}
.hflex--jcse {
  justify-content: space-evenly;
}
.hflex--aic {
  align-items: center;
}
.hflex--jcfe {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/HFlex/HFlex.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADF;AAGE;EACE,8BAAA;AADJ;AAIE;EACE,6BAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,yBAAA;AAJJ","sourcesContent":["@import 'src/scss/variables';\n\n.hflex {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100%;\n  grid-gap: 0.5rem;\n\n  &--jcsb {\n    justify-content: space-between;\n  }\n\n  &--jcse {\n    justify-content: space-evenly;\n  }\n\n  &--aic {\n    align-items: center;\n  }\n\n  &--jcfe {\n    justify-content: flex-end;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
