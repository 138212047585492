// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
}
.container--onboarding {
  padding: 16px 16px 0 16px;
}
.container--full {
  padding: 12px 14px 16px;
}
.container--with-button {
  padding: 12px 14px 24px;
}
.container--without-button {
  padding: 12px 14px 32px;
}`, "",{"version":3,"sources":["webpack://./src/hoc/Container/Container.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,uBAAA;AAFJ;AAKE;EACE,uBAAA;AAHJ;AAME;EACE,uBAAA;AAJJ","sourcesContent":["@import 'src/scss/variables';\n\n.container {\n  width: 100%;\n\n  &--onboarding {\n    padding: 16px 16px 0 16px;\n  }\n\n  &--full {\n    padding: 12px 14px 16px;\n  }\n\n  &--with-button {\n    padding: 12px 14px 24px;\n  }\n\n  &--without-button {\n    padding: 12px 14px 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
