import React from 'react'
import './Card.scss'
import { ColorType } from "../../types";

interface ICard extends React.HTMLAttributes<HTMLDivElement> {
    color: ColorType
    isOnboarding?: boolean
}

const Card: React.FC<ICard> = (props) => {
    const {
        color,
        children,
        onClick,
        className,
        isOnboarding,
        onMouseOver,
        onMouseOut
    } = props

    const css: Array<string | undefined> = ["card"]
    css.push(color)
    css.push(className)
    if (isOnboarding) {
        css.push(`card--onboarding`)
    }
    if (onClick) {
        css.push('clickable')
    }

    return (
        <div
            className={css.filter(Boolean).join(" ")}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Card