import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMaterialsReducer {
    materials: any[] | null
}

const initialState: IMaterialsReducer = {
    materials: null
}

export const materialsReducer = createSlice({
    name: "materials",
    initialState,
    reducers: {
        setMaterials(state, action: PayloadAction<any[]>) {
            state.materials = action.payload;
        },
    },
})

export const {
    setMaterials,
} = materialsReducer.actions;

export default materialsReducer.reducer;
