// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.divider {
  margin: 1rem 0;
  height: 1px;
  width: 100%;
  background-color: #3C3C41;
  position: relative;
}
.divider:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "Прошедшие";
  width: 72px;
  height: 17px;
  background-color: #3C3C41;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.4;
  padding: 4px 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Calendar/Calendar.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAMhB;EACE,cAAA;EACA,WAAA;EACA,WAAA;EACA,yBCVK;EDWL,kBAAA;AAJF;AAME;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,yBCrBG;EDsBH,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAJJ","sourcesContent":["@import \"src/scss/variables\";\n\n.calendar {\n\n}\n\n.divider {\n  margin: 1rem 0;\n  height: 1px;\n  width: 100%;\n  background-color: $grey;\n  position: relative;\n\n  &:after {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    content: 'Прошедшие';\n    width: 72px;\n    height: 17px;\n    background-color: $grey;\n    border-radius: 12px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 12px;\n    line-height: 1.4;\n    padding: 4px 8px;\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
