import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { RootState } from "../store";
import { useQueries } from "@tanstack/react-query";
import { n8nProvider } from "../api";
import { setExperts } from "../reducers/expertsReducer";
import { setInitials } from "../reducers/formReducer";
import { setUser } from "../reducers/userReducer";
import Modal from "react-modal";
import { downloadImageAsBase64 } from "../helpers";
import { setImages } from "../reducers/imagesReducer";
import { setIsAppLoading } from "../reducers/loaderReducer";
import { setMaterials } from "../reducers/materialsReducer";

const ImageDownloader = () => {
    const dispatch = useDispatch()
    const [initData] = useInitData()
    const { experts } = useSelector((state: RootState) => state.experts)
    const { form } = useSelector((state: RootState) => state.form)

    const results = useQueries({
        queries: [
            {
                queryKey: ["experts"],
                queryFn: async () => {
                    const response = await n8nProvider.get("?action=experts")
                    return response.data[0].data
                }
            },
            {
                queryKey: ["user"],
                queryFn: async () => {
                    const response = await n8nProvider.get(`?action=users&userId=${initData?.user?.id}`)
                    return response.data[0].data
                },
                enabled: !!initData?.user?.id
            },
            {
                queryKey: ["materials"],
                queryFn: async () => {
                    return n8nProvider.get("?action=documents")
                        .then((response) => {
                            return response.data[0].data
                        })
                }
            }
        ]
    })

    if (results[0].isSuccess) {
        dispatch(setExperts(results[0].data))

        if (Object.keys(form).length === 0) {
            dispatch(setInitials(results[0].data))
        }
    }

    if (results[1].isSuccess) {
        dispatch(setUser(results[1].data[0]))
    }

    if (results[2].isSuccess) {
        dispatch(setMaterials(results[2].data))
    }

    useEffect(() => {
        Modal.setAppElement('.app');
    }, [])

    useEffect(() => {
        if (experts === null) return

        const imageLink: Record<string, string> = {
            // onboarding page
            "onboarding-1": "https://strapi.verstka.agency/uploads/1_69b8b5c774.svg",
            "onboarding-2": "https://strapi.verstka.agency/uploads/2_b3044cc4a5.svg",
            "onboarding-3": "https://strapi.verstka.agency/uploads/3_10d85c2965.svg",
            "onboarding-4": "https://strapi.verstka.agency/uploads/4_fd8740c200.svg",
            // index page
            "index-1": "https://strapi.verstka.agency/uploads/True_03_3_3c85a7a42d.svg",
            "index-2": "https://strapi.verstka.agency/uploads/True_04_1_5cae9d8a2b.svg",
            "index-3": "https://strapi.verstka.agency/uploads/Question_44ef029ee1.svg",
            "index-4": "https://strapi.verstka.agency/uploads/Kalendar_3d49c6be3a.svg",
            "index-5": "https://strapi.verstka.agency/uploads/FAQ_ea1e673d2c.svg",
            // ask question page
            "no-question": "https://strapi.verstka.agency/uploads/True_16_1_eb6b183f4f.svg",
            // iconы
            "icon-accordion": "https://strapi.verstka.agency/uploads/Caret_Down_35d86ff99c.svg",
            "icon-chevron-right": "https://strapi.verstka.agency/uploads/Caret_Right_afc6e4ce43.svg",
            "icon-arrow-right": "https://strapi.verstka.agency/uploads/Arrow_Right_e23eebf2a4.svg",
            "icon-ar": "https://strapi.verstka.agency/uploads/logo_e3d1a34a8d.svg",
            "icon-question": "https://strapi.verstka.agency/uploads/help_circle_1a1abaa01f.svg",
            "icon-success": "https://strapi.verstka.agency/uploads/True_13_1_ce537304ad.svg",
            "icon-validate": "https://strapi.verstka.agency/uploads/True_15_1_b7a44a9f65.svg",
            "icon-error": "https://strapi.verstka.agency/uploads/True_14_1_f990076041.svg",
            "icon-file": "https://strapi.verstka.agency/uploads/file_b6ec012574.svg",
            "icon-correct": "https://strapi.verstka.agency/uploads/Right_2f047c3c1e.svg",
            "icon-correct-checkbox": "https://strapi.verstka.agency/uploads/Right_02ad2b353b.svg",
            "icon-close": "https://strapi.verstka.agency/uploads/Close_d3d6dc8749.svg",
            "icon-lock": "https://strapi.verstka.agency/uploads/Lock_Open_ba1c0f4c8b.svg",
            "icon-plus": "https://strapi.verstka.agency/uploads/Plus_f380b1ce75.svg"
        };

        experts?.forEach((expert) => {
            imageLink[`experts-${expert.id}-expertImage`] = (expert?.attributes?.expertImage)
            imageLink[`experts-${expert.id}-expertImageThumb`] = (expert?.attributes?.expertImageThumb)
            imageLink[`experts-${expert.id}-companyLogo`] = (expert?.attributes?.companyLogo)
            imageLink[`experts-${expert.id}-memeImage`] = (expert?.attributes?.memeImage)
        })

        const promises = Object.keys(imageLink).map(async (key) => {
            const image = await downloadImageAsBase64(imageLink[key]);
            if (typeof image === 'string') {
                dispatch(setImages({ name: key, image: image }));
            }
        });

        Promise.all(promises)
            .then(() => {
            })
            .catch((error) => {
                console.error('Ошибка при загрузке изображений:', error);
            })
            .finally(() => {
                dispatch(setIsAppLoading(false));
            })
    }, [
        experts,
        dispatch,
    ]);

    return null
}

export default ImageDownloader