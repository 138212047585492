import React from 'react'
import './Bar.scss'

interface IBar {
    isPassed: boolean
}

const Bar: React.FC<IBar> = (props) => {
    const { isPassed } = props

    const css = ["bar"]
    css.push(`bar--${isPassed}`)

    return (
        <div className={css.filter(Boolean).join(" ")}></div>
    )
}

export default Bar