import React from 'react'
import './P.scss'
import { ColorType } from "../../types";

type ParagraphVariant = "int-1" | "int-2"

interface IParagraph extends React.HTMLAttributes<HTMLParagraphElement> {
    variant?: ParagraphVariant
    color?: ColorType
    align?: "left" | "center" | "right"
}

const P: React.FC<IParagraph> = (props) => {
    const {
        children,
        variant = "int-1",
        color = "black",
        className,
        align = "left"
    } = props

    const css: Array<string | undefined> = ["p"]
    css.push(`p--${variant}`)
    css.push(`p--${color}`)
    css.push(`p--${align}`)
    css.push(className)

    return (
        <p className={css.filter(Boolean).join(" ")}>{children}</p>
    )
}

export default P