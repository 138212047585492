import React, { useRef, useState } from 'react'
import Layout from "../../hoc/Layout/Layout";
import Textarea from "../../components/Textarea/Textarea";
import ExpertCard from "../../components/ExpertCard/ExpertCard";
import Card from "../../components/Card/Card";
import { useGetExpert } from "../../hooks";
import Heading from "../../components/Heading/Heading";
import HFlex from "../../components/HFlex/HFlex";
import './AskQuestion.scss'
import VFlex from "../../components/VFlex/VFlex";
import Checkbox from "../../components/Checkbox/Checkbox";
import P from "../../components/P/P";
import ChipField from "../../components/ChipField/ChipField";
import { Form, Formik } from "formik";
import * as Yup from "yup"
import axios from "axios";
import { ColorType } from "../../types";
import { setModalOptions } from "../../reducers/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { RootState } from "../../store";
import { setInitial, setTopic } from "../../reducers/formReducer";
import { useQueryClient } from '@tanstack/react-query'
import { runTypograph } from "../../helpers";

const AskQuestion = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { expert } = useGetExpert()

    const { images } = useSelector((state: RootState) => state.images)
    const { form } = useSelector((state: RootState) => state.form)
    const { user } = useSelector((state: RootState) => state.user)

    const isSent = useRef<boolean>(false)

    const [selected, setSelected] = useState<number>(form[expert?.id]?.topic ?? 0)
    const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false)

    type ModalVariants = {
        [key: string]: {
            layout: React.ReactNode
            color: ColorType
        }
    }

    const modalVariants: ModalVariants = {
        success: {
            color: "green",
            layout:
                <VFlex alignItems={"aic"}>
                    <img src={images["icon-success"]} alt="" />
                    <Heading
                        size={3}
                        color={"black"}
                        align={"center"}
                    >
                        {"Вопрос задан!"}
                        <br />
                        {runTypograph("Следите за календарём, чтобы не пропустить день ответа")}
                    </Heading>
                </VFlex>
        },
        error: {
            color: "pink",
            layout:
                <VFlex alignItems={"aic"}>
                    <img src={images["icon-error"]} alt="" />
                    <Heading
                        size={3}
                        color={"black"}
                        align={"center"}
                    >
                        {"Не получилось задать вопрос."}
                        <br />
                        {"Попробуйте ещё раз"}
                    </Heading>
                </VFlex>
        },
        validate: {
            color: "pink",
            layout:
                <VFlex alignItems={"aic"}>
                    <img src={images["icon-validate"]} alt="" />
                    <Heading
                        size={3}
                        color={"black"}
                        align={"center"}
                    >
                        {runTypograph("Пожалуйста, заполните все поля, чтобы задать вопрос")}
                    </Heading>
                </VFlex>
        }
    }

    const topics = [
        "Карьера",
        "Компания",
        "Навыки",
        "Другое"
    ]

    const schema = Yup.object({
        question: Yup.string().required(),
        consultation: Yup.boolean(),
        topic: Yup.string().required()
    })

    const handleSubmit = (values: any, isValid: boolean) => {
        if (isSent.current) return
        isSent.current = true
        if (!isValid) {
            dispatch(setModalOptions({
                modal: {
                    isOpen: true,
                    color: modalVariants["validate"].color,
                    layout: modalVariants["validate"].layout
                }
            }))
            return
        }
        axios.post(`${process.env.REACT_APP_N8N_URL_POST}?action=send-question&expertId=${expert?.id}&userId=${user?.attributes?.user_id}`, {
            question_text: values.question.trim(),
            consultation: values.consultation,
            topic: topics[values.topic],
            expert: {
                connect: [
                    { id: expert?.id },
                ]
            },
            bot_user: {
                connect: [
                    { id: user?.id }
                ]
            }
        })
            .then(() => {
                dispatch(setInitial({ id: expert?.id }))
                queryClient.invalidateQueries()
                dispatch(setModalOptions({
                    modal: {
                        isOpen: true,
                        color: modalVariants["success"].color,
                        layout: modalVariants["success"].layout
                    }
                }))
                navigate(`/experts/${expert?.id}`)
            })
            .catch(() => {
                dispatch(setModalOptions({
                    modal: {
                        isOpen: true,
                        color: modalVariants["error"].color,
                        layout: modalVariants["error"].layout
                    }
                }))
            })
    }

    return (
        <Formik
            onSubmit={() => {
            }}
            initialValues={{
                question: form[expert?.id]?.text,
                consultation: form[expert?.id]?.consultation,
                topic: form[expert?.id]?.topic,
            }}
            enableReinitialize={true}
            validationSchema={schema}
            validateOnMount={true}
        >
            {({ values, setFieldValue, isValid, isSubmitting }) => {
                return (
                    <Layout
                        containerVariant={"with-button"}
                        stickyVariant={isNoteOpen ? "no-background" : "expert"}
                        color={isNoteOpen ? "green" : "black"}
                        buttons={[
                            {
                                children: isNoteOpen ? " Понятно" : "Задать вопрос",
                                variant: isNoteOpen ? "primary" : "secondary",
                                onClick: () => {
                                    if (isNoteOpen) {
                                        setIsNoteOpen(false)
                                    } else {
                                        handleSubmit(values, isValid)
                                    }
                                }
                            }
                        ]}
                    >
                        <>
                            <BackButton
                                onClick={() => navigate(-1)}
                            />
                            {
                                isNoteOpen ?
                                    <div className={"ask-question__modal"}>
                                        <Heading size={2}>О чем спросить?</Heading>
                                        <Heading size={3}>
                                            {
                                                runTypograph("Строгих правил нет — спрашивать можно правда о чём угодно! А вот пара советов на случай, если вопрос не формулируется:")
                                            }
                                        </Heading>
                                        <ul>
                                            <li>
                                                {runTypograph("прочитай ещё раз профиль эксперта — может, у тебя есть вопросы про карьерный путь, атмосферу внутри компании или стартерпак скиллов для новичка?")}
                                            </li>
                                            <li>
                                                {runTypograph("вспомни, что тебя волнует в своей карьере и уточни, как это работает у эксперта: от рабочих процессов до общения с коллегами")}
                                            </li>
                                            <li>{runTypograph("представь, что ты болтаешь с другом — не стесняйся делиться личным или задавать «глупые вопросы»")}
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    <Form>
                                        <div className="ask-question">
                                            <VFlex>
                                                <P
                                                    className={"required"}
                                                    color={"white"}
                                                >
                                                    Эксперт
                                                </P>
                                                <Card
                                                    color={"grey"}
                                                >
                                                    <ExpertCard expert={expert} />
                                                </Card>
                                                <Card
                                                    color={"grey"}
                                                    className={"ask-question__card"}
                                                    onClick={() => setIsNoteOpen(true)}
                                                >
                                                    <HFlex
                                                        justifyContent={"jcsb"}
                                                    >
                                                        <Heading
                                                            size={3}
                                                            color={"white"}
                                                        >
                                                            О чем спросить эксперта
                                                        </Heading>
                                                        <img src={images["icon-question"]} alt="" />
                                                    </HFlex>
                                                </Card>
                                                <Textarea
                                                    name={"question"}
                                                    id={expert?.id}
                                                />
                                                <Checkbox
                                                    id={expert?.id}
                                                    name={"consultation"}
                                                >
                                                    {runTypograph("Хочу получить консультацию от эксперта")}
                                                </Checkbox>
                                                <VFlex>
                                                    <P
                                                        color={"white"}
                                                        className={"required"}
                                                    >
                                                        Тема вопроса
                                                    </P>
                                                    <div className={"ask-question__chipfields-wrapper"}>
                                                        <div className={"ask-question__chipfields"}>
                                                            {
                                                                topics.map((topic: string, number) => {
                                                                    return (
                                                                        <ChipField
                                                                            color={"grey"}
                                                                            variant={"big"}
                                                                            isSelected={selected === number}
                                                                            onClick={(event: any) => {
                                                                                setSelected(number)
                                                                                dispatch(setTopic({
                                                                                    id: expert?.id,
                                                                                    topic: number
                                                                                }))
                                                                            }}
                                                                        >
                                                                            {runTypograph(topic)}
                                                                        </ChipField>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </VFlex>
                                            </VFlex>
                                        </div>
                                    </Form>
                            }
                        </>
                    </Layout>
                )
            }}
        </Formik>
    )
}

export default AskQuestion