// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p {
  line-height: 1.4;
  font-weight: 400;
  -webkit-user-select: none;
          user-select: none;
}
.p--int-1 {
  font-size: 14px;
}
.p--int-2 {
  font-size: 12px;
}
.p--white {
  color: white;
}
.p--orange {
  color: #EB652B;
}
.p--pink {
  color: #B2A7D9;
}
.p--yellow {
  color: #f7f360;
}
.p--black {
  color: black;
}
.p--weakWhite {
  color: rgba(255, 255, 255, 0.5);
}
.p--left {
  text-align: left;
}
.p--center {
  text-align: center;
}
.p--right {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/P/P.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,gBAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,cCjBK;ADaT;AAOE;EACE,cCtBK;ADiBT;AAQE;EACE,cCvBK;ADiBT;AASE;EACE,YAAA;AAPJ;AAUE;EACE,+BCnCQ;AD2BZ;AAWE;EACE,gBAAA;AATJ;AAYE;EACE,kBAAA;AAVJ;AAaE;EACE,iBAAA;AAXJ","sourcesContent":["@import 'src/scss/variables';\n\n.p {\n  line-height: 1.4;\n  font-weight: 400;\n  user-select: none;\n\n  &--int-1 {\n    font-size: 14px;\n  }\n\n  &--int-2 {\n    font-size: 12px;\n  }\n\n  &--white {\n    color: white;\n  }\n\n  &--orange {\n    color: $orange;\n  }\n\n  &--pink {\n    color: $purple;\n  }\n\n  &--yellow {\n    color: $yellow;\n  }\n\n  &--black {\n    color: black;\n  }\n\n  &--weakWhite {\n    color: $weakWhite;\n  }\n\n  &--left {\n    text-align: left;\n  }\n\n  &--center {\n    text-align: center;\n  }\n\n  &--right {\n    text-align: right;\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
