// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-height: 588px) {
  .main {
    flex-grow: 1;
    min-height: 100svh;
  }
}`, "",{"version":3,"sources":["webpack://./src/hoc/Layout/Layout.scss"],"names":[],"mappings":"AAIE;EAFF;IAGI,YAAA;IACA,kBAAA;EAFF;AACF","sourcesContent":["@import 'src/scss/variables';\n\n.main {\n\n  @media all and (min-height: 588px) {\n    flex-grow: 1;\n    min-height: 100svh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
