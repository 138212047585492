import React, { useEffect, useState } from 'react'
import Layout from "../../hoc/Layout/Layout";
import './Calendar.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Card from "../../components/Card/Card";
import VFlex from "../../components/VFlex/VFlex";
import ExpertCard from "../../components/ExpertCard/ExpertCard";
import HFlex from "../../components/HFlex/HFlex";
import P from "../../components/P/P";
import IconWithRound from "../../components/IconWithRound/IconWithRound";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

const Calendar = () => {
    const { experts } = useSelector((state: RootState) => state.experts)
    const { images } = useSelector((state: RootState) => state.images)
    const navigate = useNavigate()

    const [past, setPast] = useState<any[]>([])
    const [future, setFuture] = useState<any[]>([])

    useEffect(() => {
        if (!experts) return
        setPast(experts?.filter((expert) => Date.parse(expert?.attributes?.liveDate) < Date.now())?.sort((a: any, b: any) => {
            return Date.parse(b?.attributes?.liveDate) - Date.parse(a?.attributes?.liveDate)
        }))
        setFuture(
            experts?.filter((expert) => Date.parse(expert?.attributes?.liveDate) > Date.now())?.sort((a: any, b: any) => {
                return Date.parse(a?.attributes?.liveDate) - Date.parse(b?.attributes?.liveDate)
            })
        )
    }, [experts])

    const dayName = [
        "Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"
    ]

    return (
        <Layout
            containerVariant={"without-button"}
        >
            <BackButton
                onClick={() => navigate("/")}
            />
            <div className="calendar">
                <VFlex>
                    {future?.map((expert: any, index: number) => {
                        return (
                            <>
                                <P
                                    color={"yellow"}
                                >
                                    {`${dayName[new Date(expert?.attributes?.liveDate).getDay()]}, ${formatDate(expert?.attributes?.liveDate)}`}
                                </P>
                                <Card
                                    key={index}
                                    color={"grey"}
                                    onClick={() => {
                                        navigate(`/experts/${expert.id}`)
                                    }}
                                >
                                    <HFlex justifyContent={"jcsb"}>
                                        <ExpertCard expert={expert} />
                                        <IconWithRound image={
                                            <img src={images["icon-arrow-right"]} alt="" />
                                        }
                                                       color={"black"}
                                        />
                                    </HFlex>
                                </Card>
                            </>
                        )
                    })}
                    {
                        past?.length ?
                            <div className={"divider"}></div>
                            : null
                    }
                    {past?.map((expert: any, index: number) => {
                        return (
                            <>
                                <P color={"weakWhite"}>
                                    {`${dayName[new Date(expert?.attributes?.liveDate).getDay()]}, ${formatDate(expert?.attributes?.liveDate)}`}
                                </P>
                                <Card
                                    key={index}
                                    color={"grey"}
                                    onClick={() => {
                                        navigate(`/experts/${expert.id}`)
                                    }}
                                >
                                    <HFlex justifyContent={"jcsb"}>
                                        <ExpertCard expert={expert} />
                                        <IconWithRound
                                            image={
                                                <img src={images["icon-arrow-right"]} alt="" />
                                            }
                                            color={"black"}
                                        />
                                    </HFlex>
                                </Card>
                            </>
                        )
                    })}
                </VFlex>
            </div>
        </Layout>
    )
}
export default Calendar