import "./Index.scss";
import { useNavigate } from "react-router-dom";
import Layout from "../../hoc/Layout/Layout";
import Heading from "../../components/Heading/Heading";
import Card from "../../components/Card/Card";
import HFlex from "../../components/HFlex/HFlex";
import VFlex from "../../components/VFlex/VFlex";
import IconWithRound from "../../components/IconWithRound/IconWithRound";
import P from "../../components/P/P";
import ym from "react-yandex-metrika";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { n8nProvider } from "../../api";

const Index = () => {
    const { images } = useSelector((state: RootState) => state.images)
    const { user } = useSelector((state: RootState) => state.user)
    const navigate = useNavigate();

    const { refetch: makeExpertsViewed } = useQuery({
        queryKey: ["isExpertsViewed"],
        queryFn: async () => {
            await n8nProvider.get(`?action=experts-viewed&userId=${user?.id}`)
        },
        enabled: false
    })

    return (
        <Layout>
            <div className="index">
                <Card
                    color={"green"}
                    onClick={() => {
                        navigate("/onboarding");
                        ym("hit", "/onboarding");
                        ym("reachGoal", "page_visit_onboarding");
                    }}
                    isOnboarding={true}
                >
                    <HFlex justifyContent={"jcsb"} alignItems={"aic"}>
                        <Heading size={3}>Что здесь происходит?</Heading>
                        <img src={images["icon-chevron-right"]} alt="" />
                    </HFlex>
                </Card>
                <Card
                    color={"yellow"}
                    onClick={() => {
                        navigate("/experts");
                        ym("hit", "/experts");
                        ym("reachGoal", "page_visit_experts");
                        if (!user?.attributes?.isExpertsViewed) {
                            makeExpertsViewed()
                        }
                    }}
                    isOnboarding={true}
                >
                    <VFlex
                        variant={"pre-normal"}
                    >
                        <img
                            src={images["index-1"]}
                            alt=""
                        />
                        <HFlex justifyContent={"jcsb"}>
                            <Heading size={1}>
                                Узнать правду
                                <br />у экспертов
                            </Heading>
                            <IconWithRound
                                color={"black"}
                                image={
                                    <img src={images["icon-arrow-right"]} alt="" />
                                }
                            />
                        </HFlex>
                    </VFlex>
                </Card>
                <div className="main__grid">
                    <Card
                        color={"pink"}
                        onClick={() => {
                            navigate("/materials");
                            ym("hit", "/materials");
                            ym("reachGoal", "page_visit_materials");
                        }}
                        isOnboarding={true}
                    >
                        <VFlex
                            variant={"pre-normal"}
                        >
                            <img
                                src={images["index-2"]}
                                alt=""
                            />
                            <Heading size={2}>Полезные материалы</Heading>
                        </VFlex>
                    </Card>
                    <Card
                        color={"orange"}
                        onClick={() => {
                            navigate("/my-questions");
                            ym("hit", "/my-questions");
                            ym("reachGoal", "page_visit_my_questions");
                        }}
                        isOnboarding={true}
                    >
                        <VFlex
                            variant={"pre-normal"}>
                            <img
                                src={images["index-3"]}
                                alt=""
                            />
                            <Heading size={2}>
                                Мои
                                <br />
                                вопросы
                            </Heading>
                        </VFlex>
                    </Card>
                    <Card
                        color={"purple"}
                        onClick={() => {
                            navigate("/calendar");
                            ym("hit", "/calendar");
                            ym("reachGoal", "page_visit_calendar");
                        }}
                        isOnboarding={true}
                    >
                        <VFlex
                            variant={"pre-normal"}>
                            <img
                                src={images["index-4"]}
                                alt=""
                            />
                            <Heading size={2}>Календарь</Heading>
                        </VFlex>
                    </Card>
                    <Card
                        color={"green"}
                        onClick={() => {
                            navigate("/faq");
                            ym("hit", "/faq");
                            ym("reachGoal", "page_visit_faq");
                        }}
                        isOnboarding={true}
                    >
                        <VFlex
                            variant={"pre-normal"}>
                            <img
                                src={images["index-5"]}
                                alt=""
                            />
                            <Heading size={2}>FAQ</Heading>
                        </VFlex>
                    </Card>
                </div>
                <HFlex
                    justifyContent={"jcsb"}
                    className={"index__logo"}
                >
                    <P
                        variant={"int-2"}
                        color={"weakWhite"}
                    >
                        Сделано в Ар
                    </P>
                    <HFlex className={"index__hflex"}>
                        <img src={images["icon-ar"]} alt="" />
                        <P
                            variant={"int-1"}
                            color={"weakWhite"}
                        >
                            АГЕНТСТВО
                            <br />
                            HR-МАРКЕТИНГА
                        </P>
                    </HFlex>
                </HFlex>
            </div>
        </Layout>
    );
};

export default Index;
