import "./scss/general.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Onboarding from "./pages/Onboarding/Onboarding";
import Faq from "./pages/Faq/Faq";
import Index from "./pages/Index/Index";
import Experts from "./pages/Experts/Experts";
import Expert from "./pages/Expert/Expert";
import AskQuestion from "./pages/AskQuestion/AskQuestion";
import Materials from "./pages/Materials/Materials";
import MyQuestions from "./pages/MyQuestions/MyQuestions";
import Calendar from "./pages/Calendar/Calendar";

import Modalka from "./components/Modalka/Modalka";

const App = () => {

    return (
        <div className="app">
            <BrowserRouter>
                <Routes>
                    <Route path={"*"} element={<Index />} />
                    <Route path={"/"} element={<Index />} />
                    <Route path={"/onboarding"} element={<Onboarding />} />
                    <Route path={"/ask-question/:id"} element={<AskQuestion />} />
                    <Route path={"/faq"} element={<Faq />} />
                    <Route path={"/experts"} element={<Experts />} />
                    <Route path={"/experts/:id"} element={<Expert />} />
                    <Route path={"/materials"} element={<Materials />} />
                    <Route path={"/my-questions"} element={<MyQuestions />} />
                    <Route path={"/calendar"} element={<Calendar />} />
                </Routes>
            </BrowserRouter>
            <Modalka />
        </div>

    );
}

export default App;
