import React from 'react'
import './Loader.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Loader = () => {
    const { isAppLoading, isPageLoading } = useSelector((state: RootState) => state.loader)

    if (isAppLoading || isPageLoading) {
        return (
            <div className={"loader"}>
                <div className={"loader__circles"}>
                    <div className={"loader__circle"}>
                        <div className="loader__round loader__round--yellow"></div>
                    </div>
                    <div className={"loader__circle"}>
                        <div className="loader__round loader__round--pink"></div>
                    </div>
                    <div className={"loader__circle"}>
                        <div className="loader__round loader__round--purple"></div>
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default Loader