import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserReducer {
    user: any
}

const initialState: IUserReducer = {
    user: null
}

export const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<any>) {
            state.user = action.payload;
        },
        setIsOnboardingPassed(state, action: PayloadAction<boolean>) {
            state.user.attributes.isOnboardingPassed = action.payload
        }
    },
})

export const {
    setUser,
    setIsOnboardingPassed
} = userReducer.actions;

export default userReducer.reducer;
