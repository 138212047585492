// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expert {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  grid-gap: 1rem;
  animation: opacity 400ms forwards;
}
.expert__more {
  margin-top: 1rem;
}
.expert .card {
  padding-bottom: 1.5rem;
}
.expert__logo {
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
}
.expert__logo--severstal {
  top: 1rem;
  left: 1rem;
}
.expert__photo {
  max-width: 100%;
  object-fit: contain;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.expert__image {
  overflow: hidden;
  position: relative;
  width: calc(100% + 2rem);
  height: 270px;
  margin: -1rem -1rem 0;
}
.expert__meme {
  border-radius: 12px;
  width: 100%;
  height: auto;
  object-fit: contain;
}
.expert__title {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
}
.expert__recommendation {
  display: flex;
  justify-content: center;
}
.expert__more-title {
  padding-left: 1rem;
}
.expert__card {
  height: 161px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Expert/Expert.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;EACA,iCAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,sBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,eAAA;EACA,cAAA;AAHJ;AAKI;EACE,SAAA;EACA,UAAA;AAHN;AAOE;EACE,eAAA;EACA,mBAAA;EACA,4BAAA;EACA,6BAAA;AALJ;AAQE;EACE,gBAAA;EACA,kBAAA;EACA,wBAAA;EACA,aAAA;EACA,qBAAA;AANJ;AASE;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAPJ;AAUE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AARJ;AAWE;EACE,aAAA;EACA,uBAAA;AATJ;AAYE;EACE,kBAAA;AAVJ;AAaE;EACE,aAAA;AAXJ","sourcesContent":["@import 'src/scss/variables';\n\n.expert {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  grid-gap: 1rem;\n  animation: opacity 400ms forwards;\n\n  &__more {\n    margin-top: 1rem;\n  }\n\n  & .card {\n    padding-bottom: 1.5rem;\n  }\n\n  &__logo {\n    position: absolute;\n    bottom: 0.75rem;\n    right: 0.75rem;\n\n    &--severstal {\n      top: 1rem;\n      left: 1rem;\n    }\n  }\n\n  &__photo {\n    max-width: 100%;\n    object-fit: contain;\n    border-top-left-radius: 24px;\n    border-top-right-radius: 24px;\n  }\n\n  &__image {\n    overflow: hidden;\n    position: relative;\n    width: calc(100% + 2rem);\n    height: 270px;\n    margin: -1rem -1rem 0;\n  }\n\n  &__meme {\n    border-radius: 12px;\n    width: 100%;\n    height: auto;\n    object-fit: contain;\n  }\n\n  &__title {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 0.25rem;\n  }\n\n  &__recommendation {\n    display: flex;\n    justify-content: center;\n  }\n\n  &__more-title {\n    padding-left: 1rem;\n  }\n\n  &__card {\n    height: 161px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
