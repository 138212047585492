import React from 'react'
import './ProgressBar.scss'
import Bar from "./Bar/Bar";

interface IProgressBar {
    count: number
    current: number
}

const ProgressBar: React.FC<IProgressBar> = (props) => {
    const { count, current } = props
    const array = new Array(count).fill("")

    return (
        <div className={"progress-bar"}>
            {array.map((_, index) => {
                return <Bar isPassed={current >= index} key={index} />
            })}
        </div>
    )
}

export default ProgressBar