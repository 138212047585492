// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox {
  cursor: pointer;
  display: flex;
  grid-gap: 0.5rem;
  align-items: flex-end;
  -webkit-user-select: none;
          user-select: none;
}
.checkbox__box {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox__box--checked {
  background-color: #C3E7B3;
}
.checkbox__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}
.checkbox input {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE,0CCTQ;EDUR,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAGI;EACE,yBCfE;ADcR;AAKE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAHJ;AAME;EACE,aAAA;AAJJ","sourcesContent":["@import 'src/scss/variables';\n\n.checkbox {\n  cursor: pointer;\n  display: flex;\n  grid-gap: 0.5rem;\n  align-items: flex-end;\n  user-select: none;\n\n  &__box {\n    background-color: $weakWhite;\n    border-radius: 4px;\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &--checked {\n      background-color: $green;\n    }\n  }\n\n  &__text {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16.8px;\n  }\n\n  input {\n    display: none;\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
