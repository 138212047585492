import React, { useEffect } from 'react'
import './Container.scss'
import { ColorType, ContainerVariant } from "../../types";
import { COLORS } from "../../helpers";
import { useLocation } from "react-router-dom";

interface IContainer extends React.HTMLAttributes<HTMLDivElement> {
    variant?: ContainerVariant
    color?: ColorType
}

const Container: React.FC<IContainer> = (props) => {
    const { children, variant = "full", color = "black" } = props
    const location = useLocation()

    useEffect(() => {
        const body: HTMLElement | null = document.querySelector("body")
        if (body) {
            body.style.backgroundColor = COLORS[color]
        }
    }, [location.pathname])

    const css: Array<string | undefined> = ["container"]
    css.push(color)
    css.push(`container--${variant}`)

    return (
        <div className={css.filter(Boolean).join(" ")}>{children}</div>
    )
}

export default Container;