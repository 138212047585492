import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IExpertsReducer {
    experts: any[] | null
}

const initialState: IExpertsReducer = {
    experts: null
}

export const expertsReducer = createSlice({
    name: "experts",
    initialState,
    reducers: {
        setExperts(state, action: PayloadAction<any[]>) {
            state.experts = action.payload;
        },
    },
})

export const {
    setExperts,
} = expertsReducer.actions;

export default expertsReducer.reducer;
