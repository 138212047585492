import React from 'react'
import './ChipField.scss'
import { ColorType } from "../../types";

type ChipFieldVariant = "big" | "small"

interface IChipField extends React.HTMLAttributes<HTMLDivElement> {
    isSelected?: boolean
    handleClick?: () => void
    color?: ColorType
    variant?: ChipFieldVariant
}

const ChipField: React.FC<IChipField> = (props) => {
    const {
        isSelected,
        onClick,
        color = "grey",
        children,
        variant = "small",
        className
    } = props

    const css: Array<string | undefined> = ["chipfield"]

    if (onClick) {
        css.push("chipfield--selectable")
    }

    css.push(color)
    css.push(`chipfield--${color}`)
    css.push(`chipfield--${variant}`)
    css.push(`chipfield--${isSelected && "selected"}`)
    css.push(className)

    const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
        onClick && onClick(event)
    }

    return (
        <div className={css.join(" ")} onClick={handleClick}>
            {children}
        </div>
    )
}

export default ChipField