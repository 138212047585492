import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRecommendationReducer {
    recommendations: {
        [id: string]: boolean
    }
}

const initialState: IRecommendationReducer = {
    recommendations: {}
}

export const recommendationReducer = createSlice({
    name: "recommendations",
    initialState,
    reducers: {
        setRecommendations(state, action: PayloadAction<string>) {
            state.recommendations[action.payload] = true;
        },
    },
})

export const {
    setRecommendations,
} = recommendationReducer.actions;

export default recommendationReducer.reducer;
