// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chipfield {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  -webkit-user-select: none;
          user-select: none;
  flex-shrink: 0;
}
.chipfield--small {
  padding: 4px 8px;
}
.chipfield--big {
  padding: 6px 16px;
}
.chipfield--orange {
  color: black;
}
.chipfield--purple {
  color: black;
}
.chipfield--pink {
  color: black;
}
.chipfield--selectable {
  cursor: pointer;
}
.chipfield--selected {
  background-color: #C3E7B3;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/ChipField/ChipField.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,cAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAOE;EACE,YAAA;AALJ;AAQE;EACE,eAAA;AANJ;AASE;EACE,yBC7BI;ED8BJ,YAAA;AAPJ","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.chipfield {\n  width: fit-content;\n  border-radius: 20px;\n  user-select: none;\n  flex-shrink: 0;\n\n  &--small {\n    padding: 4px 8px;\n  }\n\n  &--big {\n    padding: 6px 16px;\n  }\n\n  &--orange {\n    color: black;\n  }\n\n  &--purple {\n    color: black;\n  }\n\n  &--pink {\n    color: black;\n  }\n\n  &--selectable {\n    cursor: pointer;\n  }\n\n  &--selected {\n    background-color: $green;\n    color: black;\n  }\n}\n","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
