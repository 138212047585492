import React from 'react'
import './Accordion.scss'
import IconWithRound from "../IconWithRound/IconWithRound";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface IAccordion extends React.HTMLAttributes<HTMLDivElement> {
    isOpen: boolean
    title: string
}

const Accordion: React.FC<IAccordion> = (props) => {
    const {
        isOpen,
        children,
        title,
        onClick
    } = props

    const { images } = useSelector((state: RootState) => state.images)

    return (
        <div className={"accordion"}>
            <header onClick={onClick}>
                <div className={"accordion__title"}>
                    {title}
                </div>
                <IconWithRound
                    color={"black"}
                    image={<img src={images["icon-accordion"]} alt="" />}
                    className={`icon-with-round--${isOpen ? "open" : "closed"}`}
                />
            </header>
            <div
                className={`accordion__body accordion__body--${isOpen ? "open" : "closed"}`}
            >
                {children}
            </div>
        </div>
    )
}

export default Accordion