import React from 'react'
import Card from "../Card/Card";
import HFlex from "../HFlex/HFlex";
import VFlex from "../VFlex/VFlex";
import Heading from "../Heading/Heading";
import P from "../P/P";
import IconWithRound from "../IconWithRound/IconWithRound";
import ChipField from "../ChipField/ChipField";
import { formatDate, runTypograph } from "../../helpers";
import './DownloadDocument.scss'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import ym from "react-yandex-metrika";
import { n8nProvider } from "../../api";
import { setIsPageLoading } from "../../reducers/loaderReducer";

interface IDownloadDocument {
    data: any
}

const DownloadDocument: React.FC<IDownloadDocument> = (props) => {
    const { data } = props
    const { user } = useSelector((state: RootState) => state.user)
    const { images } = useSelector((state: RootState) => state.images)
    const { id, attributes } = data

    const isDocumentAvailable = Date.parse(attributes?.issueDate) - Date.now() < 0

    const { refetch: getDocument, isFetched } = useQuery({
        queryKey: ["document", id],
        queryFn: async () => {
            await n8nProvider.get(`?action=send-document&documentId=${id}&chatId=${user?.attributes?.chatId}&userId=${user?.id}`)
        },
        enabled: false
    })

    return (
        <Card
            color={"grey"}
            onClick={() => {
                getDocument()
                ym('reachGoal', 'download-material')
            }}
        >
            <HFlex
                justifyContent={"jcsb"}
            >
                <VFlex
                    variant={"small-gap"}
                >
                    <Heading
                        size={3}
                        color={"white"}
                    >
                        {runTypograph(attributes?.title)}
                    </Heading>
                    <P
                        variant={"int-2"}
                        color={"weakWhite"}
                    >
                        {runTypograph(attributes?.text)}
                    </P>
                    <HFlex
                        className={"download-document__hflex"}
                    >
                        <img src={images["icon-file"]} alt="" />
                        <P
                            variant={"int-2"}
                            color={"pink"}
                        >
                            {attributes?.docFormat}
                        </P>
                    </HFlex>
                </VFlex>
                {isDocumentAvailable
                    ?
                    isFetched ?
                        <IconWithRound
                            image={<img src={images["icon-correct"]} alt="" />}
                            color={"green"}
                        />
                        :
                        <IconWithRound
                            image={<img src={images["icon-plus"]} alt="" />}
                            color={"black"}
                        />
                    :
                    <ChipField
                        color={"purple"}
                        className={"download-document__chipfield"}
                    >
                        <HFlex>
                            <img src={images["icon-lock"]} alt="" width={16} height={16} />
                            <P
                                variant={"int-2"}
                                className={"download-document__p"}
                            >
                                {formatDate(attributes?.issueDate)}
                            </P>
                        </HFlex>
                    </ChipField>
                }
            </HFlex>
        </Card>
    )
}

export default DownloadDocument