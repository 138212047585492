import Modal from "react-modal";
import React from "react";
import { ColorType } from "../../types";
import './Modalka.scss'
import HFlex from "../HFlex/HFlex";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../reducers/modalReducer";
import { COLORS } from "../../helpers";

const Modalka = () => {
    const dispatch = useDispatch()
    const { images } = useSelector((state: RootState) => state.images)
    const { isOpen, color, layout } = useSelector((state: RootState) => state.modal.modal)

    const style = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999
        },
        content: {
            width: "311px",
            borderRadius: "24px",
            padding: "24px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: COLORS[color]
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            style={style}
        >
            <HFlex
                justifyContent={"jcfe"}
                className={"modalka__hflex"}
            >
                <img
                    src={images["icon-close"]}
                    alt=""
                    onClick={() => dispatch(closeModal())}
                />
            </HFlex>
            {layout}
        </Modal>
    )
}

export default Modalka