import "./Onboarding.scss";
import Layout from "../../hoc/Layout/Layout";
import { useState } from "react";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { ColorType } from "../../types";
import { IButton } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import P from "../../components/P/P";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { n8nProvider } from "../../api";
import Heading from "../../components/Heading/Heading";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setIsOnboardingPassed } from "../../reducers/userReducer";
import { setIsAppLoading, setIsPageLoading } from "../../reducers/loaderReducer";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { runTypograph } from "../../helpers";

const Onboarding = () => {
    const queryClient = useQueryClient()
    const STEP_COLOR: ColorType[] = ["purple", "pink", "orange", "green"]
    const [current, setCurrent] = useState<number>(0)
    const { user } = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { images } = useSelector((state: RootState) => state.images)

    const goToHome = () => {
        navigate("/")
    }

    const { data: onboarding, isSuccess, isLoading } = useQuery({
        queryKey: ["onboarding"],
        queryFn: async () => {
            const response = await n8nProvider.get("?action=onboarding-texts")
            return response.data[0].data
        },
    })

    if (isLoading) {
        dispatch(setIsPageLoading(true));
    }

    if (isSuccess) {
        dispatch(setIsPageLoading(false));
    }

    const handleNextStep = () => {
        if (current < 3) {
            setCurrent((prev) => prev + 1)
            return
        }
        if (!user?.attributes?.isOnboardingPassed) {
            dispatch(setIsAppLoading(true))
            n8nProvider.get(`?action=onboarding&userId=${user?.id}`)
                .then((response: any) => {
                    dispatch(setIsOnboardingPassed(response.data))
                    queryClient.invalidateQueries()
                    goToHome()
                    dispatch(setIsAppLoading(false))
                })
        } else {
            goToHome()
        }
    }

    const buttons: IButton[] = [
        {
            children: "Далее",
            variant: "primary",
            onClick: handleNextStep,
        },
        user?.attributes?.isOnboardingPassed &&
        {
            children: "Пропустить",
            variant: "transparent",
            onClick: goToHome,
        }
    ]

    return (
        <Layout
            color={STEP_COLOR[current]}
            buttons={buttons.filter(Boolean)}
            stickyVariant={"no-background"}
            containerVariant={"onboarding"}
        >
            <div className={"onboarding"}>
                {user?.attributes?.isOnboardingPassed &&
                    <BackButton onClick={() => navigate('/')} />
                }
                <ProgressBar
                    count={4}
                    current={current}
                />
                <div
                    className={"onboarding__content"}
                    key={current}
                >
                    <img
                        src={images[`onboarding-${current + 1}`]}
                        alt=""
                    />
                    <div className={"onboarding__text"}>
                        <Heading size={2}>
                            {runTypograph(onboarding?.[current]?.attributes?.title)}
                        </Heading>
                        <div className={"onboarding__tips"}>
                            {
                                onboarding?.[current]?.attributes?.text?.map((text: string, index: number) => {
                                    return (
                                        <P
                                            variant={"int-1"}
                                            key={index}
                                        >
                                            {runTypograph(text)}
                                        </P>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Onboarding