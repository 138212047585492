import React from 'react'
import './ExpertCard.scss'
import HFlex from "../HFlex/HFlex";
import VFlex from "../VFlex/VFlex";
import Heading from "../Heading/Heading";
import P from "../P/P";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { runTypograph } from "../../helpers";

type ExpertType = {
    imageLink: string
    name: string
    position: string;
}

interface IExpertCard {
    expert?: any
}

const ExpertCard: React.FC<IExpertCard> = (props) => {
    const { expert } = props
    const { images } = useSelector((state: RootState) => state.images)
    const name = expert?.attributes?.name
    const jobTitle = expert?.attributes?.jobTitle

    return (
        <HFlex className={"expert-card"}>
            <img src={images[`experts-${expert?.id}-expertImageThumb`]} alt="" />
            <VFlex variant={"small-gap"}>
                <Heading size={3} color={"white"}>{name}</Heading>
                <P variant={"int-2"} color={"weakWhite"}>{runTypograph(jobTitle)}</P>
            </VFlex>
        </HFlex>
    )
}

export default ExpertCard