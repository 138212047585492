// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-with-round {
  padding: 0.75rem;
  border-radius: 50%;
  transition: 250ms;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  cursor: pointer;
}
.icon-with-round img {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/IconWithRound/IconWithRound.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;AADJ","sourcesContent":["@import \"src/scss/variables\";\n\n.icon-with-round {\n  padding: 0.75rem;\n  border-radius: 50%;\n  transition: 250ms;\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 48px;\n  width: 48px;\n  cursor: pointer;\n\n  img {\n    width: 24px;\n    height: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
