// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  border: none;
  background-color: transparent;
  padding: 0.875rem 0;
  border-radius: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: center;
  cursor: pointer;
}
.button--primary {
  background-color: black;
  color: white;
}
.button--secondary {
  background-color: #C3E7B3;
  color: black;
}
.button--transparent {
  background-color: transparent;
  color: black;
}
.button--hidden {
  display: none;
}
.button img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,6BAAA;EACA,mBAAA;EACA,qBAAA;EACA,WAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAHF;AAKE;EACE,uBAAA;EACA,YAAA;AAHJ;AAME;EACE,yBCrBI;EDsBJ,YAAA;AAJJ;AAOE;EACE,6BAAA;EACA,YAAA;AALJ;AAQE;EACE,aAAA;AANJ;AASE;EACE,aAAA;EACA,cAAA;EACA,oBAAA;AAPJ","sourcesContent":["@import \"src/scss/variables\";\n\n.button {\n  border: none;\n  background-color: transparent;\n  padding: 0.875rem 0;\n  border-radius: 1.5rem;\n  width: 100%;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 19.6px;\n  text-align: center;\n  cursor: pointer;\n\n  &--primary {\n    background-color: black;\n    color: white;\n  }\n\n  &--secondary {\n    background-color: $green;\n    color: black;\n  }\n\n  &--transparent {\n    background-color: transparent;\n    color: black;\n  }\n\n  &--hidden {\n    display: none;\n  }\n\n  & img {\n    width: 1.5rem;\n    height: 1.5rem;\n    margin-right: 0.5rem;\n  }\n}\n","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
