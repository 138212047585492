// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2;
}

.h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}

.h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
}

.h1, .h2, .h3 {
  display: flex;
  align-items: center;
}
.h1--black, .h2--black, .h3--black {
  color: black;
}
.h1--white, .h2--white, .h3--white {
  color: white;
}
.h1--left, .h2--left, .h3--left {
  text-align: left;
}
.h1--center, .h2--center, .h3--center {
  text-align: center;
}
.h1--right, .h2--right, .h3--right {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/Heading/Heading.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,iBAAA;AAJJ","sourcesContent":["\n.h1 {\n  font-weight: 500;\n  font-size: 28px;\n  line-height: 1.2;\n}\n\n.h2 {\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 1.2;\n}\n\n.h3 {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 1.4;\n}\n\n.h1, .h2, .h3 {\n  display: flex;\n  align-items: center;\n\n  &--black {\n    color: black;\n  }\n\n  &--white {\n    color: white;\n  }\n\n  &--left {\n    text-align: left;\n  }\n\n  &--center {\n    text-align: center;\n  }\n\n  &--right {\n    text-align: right;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
