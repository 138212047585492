// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vflex {
  display: flex;
  flex-direction: column;
}
.vflex--normal {
  grid-gap: 1rem;
}
.vflex--average {
  grid-gap: 0.5rem;
}
.vflex--pre-normal {
  grid-gap: 0.75rem;
}
.vflex--small-gap {
  grid-gap: 4px;
}
.vflex--10px {
  grid-gap: 10px;
}
.vflex--aic {
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/VFlex/VFlex.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,cAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAOE;EACE,cAAA;AALJ;AAQE;EACE,mBAAA;AANJ","sourcesContent":["@import 'src/scss/variables';\n\n.vflex {\n  display: flex;\n  flex-direction: column;\n\n  &--normal {\n    grid-gap: 1rem;\n  }\n\n  &--average {\n    grid-gap: 0.5rem;\n  }\n\n  &--pre-normal {\n    grid-gap: 0.75rem;\n  }\n\n  &--small-gap {\n    grid-gap: 4px;\n  }\n\n  &--10px {\n    grid-gap: 10px;\n  }\n\n  &--aic {\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
