import React from 'react'
import './VFlex.scss'

interface IVFlex extends React.HTMLAttributes<HTMLDivElement> {
    variant?: "small-gap" | "normal" | "average" | "pre-normal" | "10px"
    alignItems?: "aic"
}

const VFlex: React.FC<IVFlex> = (props) => {
    const {
        children,
        className,
        variant = "normal",
        alignItems
    } = props

    const css: Array<string | undefined> = ["vflex"]
    css.push(className)
    css.push(`vflex--${variant}`)
    css.push(`vflex--${alignItems}`)

    return (
        <div className={css.filter(Boolean).join(" ")}>
            {children}
        </div>
    )
}

export default VFlex