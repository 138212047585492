import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ILoaderReducer {
    isAppLoading: boolean
    isPageLoading: boolean
}

const initialState: ILoaderReducer = {
    isAppLoading: true,
    isPageLoading: false
};

export const loaderReducer = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setIsAppLoading(state, action: PayloadAction<boolean>) {
            state.isAppLoading = action.payload
        },
        setIsPageLoading(state, action: PayloadAction<boolean>) {
            state.isPageLoading = action.payload
        }
    },
});

export const {
    setIsAppLoading,
    setIsPageLoading
} = loaderReducer.actions;

export default loaderReducer.reducer;
