import React from 'react'
import './Materials.scss'
import Card from "../../components/Card/Card";
import VFlex from "../../components/VFlex/VFlex";
import Heading from "../../components/Heading/Heading";
import P from "../../components/P/P";
import Layout from "../../hoc/Layout/Layout";
import DownloadDocument from "../../components/DownloadDocument/DownloadDocument";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { runTypograph } from "../../helpers";

const Materials = () => {
    const navigate = useNavigate()
    const { materials } = useSelector((state: RootState) => state.materials)

    return (
        <Layout
            containerVariant={"without-button"}
        >
            <BackButton onClick={() => navigate(-1)} />
            <div className="materials">
                <VFlex>
                    <Card
                        color={"pink"}
                    >
                        <VFlex>
                            <Heading size={2}>
                                {runTypograph("Что это за материалы?")}
                            </Heading>
                            <P>
                                {
                                    runTypograph("Нескучные гайды про карьеру с полезными советами. Открываются постепенно. Нажимай на плюс — отправим материал в телеграм")
                                }
                            </P>
                        </VFlex>
                    </Card>
                    {materials?.map((material: any, index: number) => {
                        return (
                            <DownloadDocument
                                data={material}
                                key={index}
                            />
                        )
                    })}
                </VFlex>
            </div>
        </Layout>
    )
}

export default Materials