// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-questions__chipfield {
  align-self: flex-start;
}
.my-questions__card {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.my-questions--index {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-questions__p {
  margin-bottom: 1rem;
}

.no-questions {
  padding-top: 98.72px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/MyQuestions/MyQuestions.scss"],"names":[],"mappings":"AAME;EACE,sBAAA;AALJ;AAQE;EACE,0CAAA;AANJ;AASE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAPJ;AAUE;EACE,mBAAA;AARJ;;AAYA;EACE,oBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;AATF","sourcesContent":["@import 'src/scss/variables';\n\n.my-questions {\n  //display: flex;\n  //justify-content: center;\n\n  &__chipfield {\n    align-self: flex-start;\n  }\n\n  &__card {\n    border: 1px solid $weakWhite;\n  }\n\n  &--index {\n    width: 25px;\n    height: 25px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  &__p {\n    margin-bottom: 1rem;\n  }\n}\n\n.no-questions {\n  padding-top: 98.72px;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  grid-gap: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
