// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  width: calc(100% + 14px);
}
.swiper-slide {
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/Slider.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;AADF;AAGE;EACE,uBAAA;EAAA,kBAAA;EACA,cAAA;AADJ","sourcesContent":["@import 'src/scss/variables';\n\n.swiper {\n  width: calc(100% + 14px);\n\n  &-slide {\n    width: fit-content;\n    flex-shrink: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
