import React, { useRef, useState } from 'react'
import './Textarea.scss'
import P from "../P/P";
import { useField } from "formik";
import { useDispatch } from "react-redux";
import { setQuestionText } from "../../reducers/formReducer";

interface ITextArea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string
    id: string
}

const Textarea: React.FC<ITextArea> = (props) => {
    const { name, id } = props

    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [field, , helpers] = useField(name)
    const [letters, setLetters] = useState<number>(field?.value?.length ?? 0)
    const dispatch = useDispatch()

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        if (event.target.value.length > 280) return
        const text = event.target.value
        setLetters(text.length)
        dispatch(setQuestionText({ id: id, text: text }))
        helpers.setValue(text)
    }

    const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (event) => {
        if (event.target.value.length > 280) return
        const text = event.target.value.trim()
        setLetters(text.length)
        dispatch(setQuestionText({ id: id, text: text }))
        helpers.setValue(text)
    }

    return (
        <div className={"textarea"}>
            <header className={"required"}>Вопрос</header>
            <div className={"textarea__body"}>
                <textarea
                    ref={textareaRef}
                    tabIndex={1}
                    name={name}
                    id=""
                    placeholder={"Начните вводить вопрос"}
                    onChange={handleChange}
                    maxLength={280}
                    value={field.value}
                    // @ts-ignore
                    enterkeyhint={"done"}
                    onKeyUp={(event) => {
                        event.preventDefault()
                        if (event.code === "Enter") {
                            if (textareaRef.current) {
                                textareaRef.current.blur()
                            }
                        }
                    }}
                    onBlur={handleBlur}
                />
                <P
                    className={`textarea__counter textarea__counter--${letters === 280 && "full"}`}
                    color={"weakWhite"}
                    variant={"int-2"}>
                    {letters}/280
                </P>
            </div>
        </div>
    )
}

export default Textarea