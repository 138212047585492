// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
}
.textarea__body {
  position: relative;
  display: flex;
  flex-direction: column;
}
.textarea__body textarea {
  background-color: #3C3C41;
  padding: 1rem;
  height: 12.375rem;
  border-radius: 1rem;
  color: white;
  resize: none;
  font-family: unset;
  border: 1px solid transparent;
}
.textarea__body textarea:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.textarea__body textarea:focus + p {
  color: white;
}
.textarea__body textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.textarea__counter {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.textarea__counter--full {
  color: #EB652B !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/Textarea.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AADF;AAGE;EACE,kBAAA;EAEA,aAAA;EACA,sBAAA;AAFJ;AAII;EACE,yBCdC;EDeD,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,6BAAA;AAFN;AAIM;EACE,aAAA;EACA,0CAAA;AAFR;AAIQ;EACE,YAAA;AAFV;AAMM;EACE,+BChCI;AD4BZ;AASE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AAPJ;AASI;EACE,yBAAA;AAPN","sourcesContent":["@import 'src/scss/variables';\n\n.textarea {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 0.75rem;\n\n  &__body {\n    position: relative;\n\n    display: flex;\n    flex-direction: column;\n\n    textarea {\n      background-color: $grey;\n      padding: 1rem;\n      height: 12.375rem;\n      border-radius: 1rem;\n      color: white;\n      resize: none;\n      font-family: unset;\n      border: 1px solid transparent;\n\n      &:focus {\n        outline: none;\n        border: 1px solid $weakWhite;\n\n        & + p {\n          color: white;\n        }\n      }\n\n      &::placeholder {\n        color: $weakWhite;\n      }\n    }\n  }\n\n  &__counter {\n    position: absolute;\n    bottom: 1rem;\n    right: 1rem;\n\n    &--full {\n      color: $orange !important;\n    }\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
