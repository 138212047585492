// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.sticky--background {
  background-color: black;
  margin: 0 -14px;
  padding: 8px 14px;
}
.sticky--expert {
  background-color: black;
  margin: 24px -14px 0;
  padding: 8px 14px;
}
.sticky--no-background {
  padding: 1rem 0;
}
@media all and (min-height: 620px) {
  .sticky {
    position: sticky;
  }
}`, "",{"version":3,"sources":["webpack://./src/hoc/Sticky/Sticky.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;AADF;AAIE;EACE,uBAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AAKE;EACE,uBAAA;EACA,oBAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAOE;EA1BF;IA2BI,gBAAA;EAJF;AACF","sourcesContent":["@import 'src/scss/variables';\n\n.sticky {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 8px;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 9;\n\n\n  &--background {\n    background-color: black;\n    margin: 0 -14px;\n    padding: 8px 14px;\n  }\n\n  &--expert {\n    background-color: black;\n    margin: 24px -14px 0;\n    padding: 8px 14px;\n  }\n\n  &--no-background {\n    padding: 1rem 0;\n  }\n\n  @media all and (min-height: 620px) {\n    position: sticky;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
