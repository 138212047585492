import { configureStore } from "@reduxjs/toolkit";
import expertsReducer from "./reducers/expertsReducer";
import userReducer from "./reducers/userReducer";
import modalReducer from "./reducers/modalReducer";
import imagesReducer from "./reducers/imagesReducer";
import loaderReducer from "./reducers/loaderReducer";
import recommendationReducer from "./reducers/recommendationsReducer";
import formReducer from "./reducers/formReducer";
import materialsReducer from "./reducers/materialsReducer";

export const store = configureStore({
    reducer: {
        experts: expertsReducer,
        user: userReducer,
        modal: modalReducer,
        images: imagesReducer,
        loader: loaderReducer,
        recommendations: recommendationReducer,
        form: formReducer,
        materials: materialsReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
