// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalka__overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.modalka__hflex {
  height: auto;
}
.modalka__content::-webkit-scrollbar {
  display: none;
}
.modalka__content--green {
  background-color: #C3E7B3;
}`, "",{"version":3,"sources":["webpack://./src/components/Modalka/Modalka.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,oCAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAQI;EACE,aAAA;AANN;AAaI;EACE,yBCzBE;ADcR","sourcesContent":["@import 'src/scss/variables';\n\n.modalka {\n\n  &__overlay {\n    z-index: 9999;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    overflow: hidden;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n\n  &__hflex {\n    height: auto;\n  }\n\n  &__content {\n    &::-webkit-scrollbar {\n      display: none;\n    }\n\n    &--pink {\n      //background-color: $pink;\n    }\n\n    &--green {\n      background-color: $green;\n    }\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
