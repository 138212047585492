// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  background-color: #3C3C41;
  padding: 1rem;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
}
.accordion header {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.accordion__title {
  flex-grow: 1;
}
.accordion .icon-with-round--open {
  rotate: 0deg;
}
.accordion .icon-with-round--closed {
  rotate: 180deg;
}
.accordion__body--open {
  max-height: 16rem;
  padding-top: 0.5rem;
  animation: opacity 400ms forwards;
}
.accordion__body--closed {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion/Accordion.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,yBCHK;EDIL,aAAA;EACA,qBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAGE;EACE,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAOI;EACE,YAAA;AALN;AAQI;EACE,cAAA;AANN;AAYI;EACE,iBAAA;EACA,mBAAA;EACA,iCAAA;AAVN;AAaI;EACE,aAAA;EACA,gBAAA;EACA,cAAA;AAXN","sourcesContent":["@import \"src/scss/variables\";\n\n.accordion {\n  background-color: $grey;\n  padding: 1rem;\n  border-radius: 1.5rem;\n  display: flex;\n  flex-direction: column;\n\n  header {\n    color: white;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n\n  &__title {\n    flex-grow: 1;\n  }\n\n  .icon-with-round {\n\n    &--open {\n      rotate: 0deg;\n    }\n\n    &--closed {\n      rotate: 180deg;\n    }\n  }\n\n  &__body {\n\n    &--open {\n      max-height: 16rem;\n      padding-top: 0.5rem;\n      animation: opacity 400ms forwards;\n    }\n\n    &--closed {\n      max-height: 0;\n      overflow: hidden;\n      padding-top: 0;\n    }\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
