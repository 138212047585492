import React, { useEffect, useRef, useState } from 'react'
import Container from "../Container/Container";
import { ColorType, ContainerVariant, StickyVariant } from "../../types";
import './Layout.scss'
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import Button, { IButton } from "../../components/Button/Button";
import Sticky from "../Sticky/Sticky";

interface ILayout extends React.HTMLAttributes<HTMLDivElement> {
    color?: ColorType
    buttons?: IButton[]
    stickyVariant?: StickyVariant
    containerVariant?: ContainerVariant
}

const Layout: React.FC<ILayout> = (props) => {
    const {
        children,
        color,
        buttons = [],
        stickyVariant,
        containerVariant = "full"
    } = props

    const { user } = useSelector((state: RootState) => state.user)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (user && !user?.attributes?.isOnboardingPassed) {
            navigate('/onboarding')
        }
    }, [user, navigate])

    const mainRef = useRef<HTMLDivElement>(null)
    const stickyRef = useRef<HTMLDivElement>(null)
    const [containerPadding, setContainerPadding] = useState<number>(0)

    useEffect(() => {
        const paddingChoices: Record<string, number> = {
            'full': 28,
            'onboarding': 16,
        }

        setContainerPadding(paddingChoices[containerVariant])
    }, [containerVariant])

    useEffect(() => {
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        if (viewportHeight < 620) return

        if (mainRef.current) {
            const stickyRefHeight = stickyRef.current ? stickyRef.current.getBoundingClientRect().height : 0
            const calculatedHeight = viewportHeight - stickyRefHeight - containerPadding
            mainRef.current.style.minHeight = `${calculatedHeight}px`
        }
    }, [mainRef, stickyRef, buttons, location.pathname, containerPadding])

    return (
        <Container
            color={color}
            variant={containerVariant}
        >
            <ScrollToTop />
            <main
                className={`main main--sticky-${buttons.length}`}
                ref={mainRef}
            >
                {children}
            </main>
            {
                buttons?.length ?
                    <Sticky
                        variant={stickyVariant}
                        ref={stickyRef}
                    >
                        {
                            buttons?.map((button, index: number) => {
                                return (
                                    <Button
                                        {...button}
                                        key={index}
                                    >
                                        {button.children}
                                    </Button>
                                )
                            })
                        }
                    </Sticky>
                    : null
            }
        </Container>
    )
}

export default Layout