import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

import App from './App';
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import { Provider } from "react-redux";
import { store } from "./store";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";
import Loader from "./components/Loader/Loader";
import { YMInitializer } from "react-yandex-metrika";
import ImageDownloader from "./helperComponents/ImageDownloader";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <YMInitializer accounts={[97277506]} options={{ webvisor: true }} />
            <QueryClientProvider client={queryClient}>
                <WebAppProvider
                    options={{
                        smoothButtonsTransition: true,
                    }}
                >
                    <ImageDownloader />
                    <App />
                    <Loader />
                </WebAppProvider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);