// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 1.5rem;
  padding: 1rem;
  -webkit-user-select: none;
          user-select: none;
}
.card--onboarding {
  padding: 1rem 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,aAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE,oBAAA;AADJ","sourcesContent":["@import 'src/scss/variables';\n\n.card {\n  border-radius: 1.5rem;\n  padding: 1rem;\n  user-select: none;\n\n  &--onboarding {\n    padding: 1rem 1.5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
