// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
  background-color: white;
  border-radius: 1.5rem;
  width: 100%;
}
.bar--true {
  background-color: white;
}
.bar--false {
  background-color: rgba(255, 255, 255, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/Bar/Bar.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,qBAAA;EACA,WAAA;AADF;AAGE;EACE,uBAAA;AADJ;AAGE;EACE,0CCVQ;ADSZ","sourcesContent":["@import 'src/scss/variables.scss';\n\n.bar {\n  background-color: white;\n  border-radius: 1.5rem;\n  width: 100%;\n\n  &--true {\n    background-color: white;\n  }\n  &--false {\n    background-color: $weakWhite;\n  }\n}","$grey: #3C3C41;\n$weakWhite: rgba(255, 255, 255, 0.5);\n$purple: #B2A7D9;\n$orange: #EB652B;\n$green: #C3E7B3;\n$yellow: #f7f360;\n$pink: #EBB9D1;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
