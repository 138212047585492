import React from 'react'
import './HFlex.scss'

interface IHFlex extends React.HTMLAttributes<HTMLDivElement> {
    justifyContent?: "jcsb" | "jcc" | "jcse" | "jcfe"
    alignItems?: "aic"
}

const HFlex: React.FC<IHFlex> = (props) => {
    const {
        justifyContent = "jcc",
        alignItems = "aic",
        children,
        className
    } = props

    const css: Array<string | undefined> = ["hflex"]
    css.push(`hflex--${justifyContent}`)
    css.push(`hflex--${alignItems}`)
    css.push(className)

    return (
        <div className={css.filter(Boolean).join(" ")}>
            {children}
        </div>
    )
}

export default HFlex