// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ask-question__card {
  border-radius: 32px;
  padding: 13px 16px;
}
.ask-question__chipfields-wrapper {
  overflow: hidden;
  margin-right: -14px;
}
.ask-question__chipfields {
  display: flex;
  grid-gap: 0.5rem;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.ask-question__chipfields::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.ask-question__chipfields > .chipfield:last-of-type {
  margin-right: 1rem;
}
.ask-question__modal {
  padding-top: 115px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  color: black;
}
.ask-question__modal ul {
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  grid-gap: 45px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AskQuestion/AskQuestion.scss"],"names":[],"mappings":"AAIE;EACE,mBAAA;EACA,kBAAA;AAHJ;AAME;EACE,gBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EAEA,wBAAA,EAAA,0BAAA;EACA,qBAAA,EAAA,YAAA;AANJ;AAQI;EACE,aAAA,EAAA,sBAAA;AANN;AASI;EACE,kBAAA;AAPN;AAWE;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AATJ;AAWI;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AATN","sourcesContent":["@import 'src/scss/variables';\n\n.ask-question {\n\n  &__card {\n    border-radius: 32px;\n    padding: 13px 16px;\n  }\n\n  &__chipfields-wrapper {\n    overflow: hidden;\n    margin-right: -14px;\n  }\n\n  &__chipfields {\n    display: flex;\n    grid-gap: 0.5rem;\n    overflow: scroll;\n\n    -ms-overflow-style: none; /* Internet Explorer 10+ */\n    scrollbar-width: none; /* Firefox */\n\n    &::-webkit-scrollbar {\n      display: none; /* Safari and Chrome */\n    }\n\n    & > .chipfield:last-of-type {\n      margin-right: 1rem;\n    }\n  }\n\n  &__modal {\n    padding-top: 115px;\n    padding-left: 14px;\n    padding-right: 14px;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 1.5rem;\n    color: black;\n\n    ul {\n      margin-bottom: 9px;\n      display: flex;\n      flex-direction: column;\n      grid-gap: 45px;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
