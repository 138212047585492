import React, { useEffect, useRef, useState } from 'react'
import { SwiperProps } from "swiper/swiper-react";
import './Slider.scss'


import "swiper/css";
import { Swiper } from 'swiper/react';
import { Autoplay } from "swiper/modules";

interface ISlider extends SwiperProps {
}

const Slider: React.FC<ISlider> = (props) => {
    const [swiperRef, setSwiperRef] = useState<any>(null);

    console.log('swiperRef', swiperRef)

    const playSwiper = (event: any) => {
        if (swiperRef !== null && swiperRef.current !== null) {
            swiperRef.autoplay.start()
        }
    }
    const pauseSwiper = (event: any) => {
        if (swiperRef !== null && swiperRef.current !== null) {
            swiperRef.autoplay.stop()
        }
    }


    return (
        <Swiper
            autoplay={{
                delay: 4000,
                pauseOnMouseEnter: true,
            }}
            onSwiper={setSwiperRef}
            loop={true}
            modules={[Autoplay]}

            {...props}
            onTouchStart={pauseSwiper}
            onTouchEnd={playSwiper}
        >
            {props.children}
        </Swiper>
    )
}

export default Slider