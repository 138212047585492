import React, { forwardRef } from 'react'
import './Button.scss'

type ButtonVariant = "primary" | "secondary" | "transparent" | "hidden"

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant
    icon?: React.ReactNode
}

const Button: React.FC<IButton> = (props: IButton) => {
    const {
        variant,
        children,
        icon
    } = props

    const css = ["button"]
    css.push(`button--${variant}`)

    return (
        <button
            className={css.join(" ")}
            {...props}
        >
            {icon && icon}
            {children}
        </button>
    )
}

export default Button