import React from 'react'
import Layout from "../../hoc/Layout/Layout";
import Card from "../../components/Card/Card";
import VFlex from "../../components/VFlex/VFlex";
import Heading from "../../components/Heading/Heading";
import P from "../../components/P/P";
import HFlex from "../../components/HFlex/HFlex";
import ChipField from "../../components/ChipField/ChipField";
import IconWithRound from "../../components/IconWithRound/IconWithRound";
import { useNavigate } from "react-router-dom";
import ExpertCard from "../../components/ExpertCard/ExpertCard";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import _ from 'lodash'
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { formatDate, runTypograph } from "../../helpers";

const Experts = () => {
    const { images } = useSelector((state: RootState) => state.images)
    const { experts } = useSelector((state: RootState) => state.experts)
    const { user } = useSelector((state: RootState) => state.user)
    const navigate = useNavigate()

    const questions = user?.attributes?.questions?.data

    return (
        <Layout
            containerVariant={"without-button"}
        >
            <BackButton onClick={() => navigate("/")} />
            <div className={"experts"}>
                <VFlex>
                    <Card
                        color={"orange"}
                    >
                        <VFlex variant={"pre-normal"}>
                            <Heading size={2}>А вот и эксперты!</Heading>
                            <P>
                                {
                                    runTypograph("Специалисты топ-компаний с большим опытом в IT и digital. Нажимай на профиль, чтобы узнать про опыт, достижения, полезные привычки и любимый мем каждого")
                                }
                            </P>
                        </VFlex>
                    </Card>
                    {
                        _.shuffle(experts)?.map((expert, index) => {

                            const questionsAmount = questions?.filter((question: any) => {
                                return question?.attributes?.expert?.data?.id === expert?.id
                            }).length

                            return (
                                <Card
                                    key={index}
                                    color={"grey"}
                                    onClick={() => {
                                        navigate(`/experts/${expert.id}`)
                                    }}
                                >
                                    <VFlex variant={"average"}>
                                        <ExpertCard expert={expert} />
                                        <HFlex>
                                            <HFlex>
                                                <HFlex>
                                                    <ChipField color={"purple"}>
                                                        <P variant={"int-2"}>
                                                            {formatDate(expert?.attributes?.liveDate)}
                                                        </P>
                                                    </ChipField>
                                                    <ChipField color={questionsAmount === 3 ? "orange" : "pink"}>
                                                        <P variant={"int-2"}>
                                                            {questionsAmount}/3 вопросов
                                                        </P>
                                                    </ChipField>
                                                </HFlex>
                                                <IconWithRound
                                                    image={
                                                        <img src={images["icon-arrow-right"]} alt={""} />
                                                    }
                                                    color={"black"}
                                                />
                                            </HFlex>
                                        </HFlex>
                                    </VFlex>
                                </Card>
                            )
                        })
                    }
                </VFlex>
            </div>
        </Layout>
    )
}

export default Experts