// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expert-card {
  display: flex;
  grid-gap: 0.75rem;
  align-items: flex-start;
}
.expert-card img {
  height: 60px;
  width: 60px;
  aspect-ratio: 1/1;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ExpertCard/ExpertCard.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;AADF;AAGE;EAEE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AAFJ","sourcesContent":["@import 'src/scss/variables';\n\n.expert-card {\n  display: flex;\n  grid-gap: 0.75rem;\n  align-items: flex-start;\n\n  img {\n    //border-radius: 1rem;\n    height: 60px;\n    width: 60px;\n    aspect-ratio: 1/1;\n    border: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
