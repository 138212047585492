import { useSelector } from "react-redux";
import { RootState } from "./store";
import { useLocation } from "react-router-dom";

export const useGetExpert = () => {
    const { experts } = useSelector((state: RootState) => state.experts)

    const location = useLocation()
    const arrayFromLocationPathname = location.pathname.split("/")
    const id = arrayFromLocationPathname[arrayFromLocationPathname.length - 1]
    const expert = experts?.filter((expert) => id == expert?.id)[0]

    return { expert }
}