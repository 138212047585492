import React, { forwardRef } from 'react'
import './Sticky.scss'
import { StickyVariant } from "../../types";

interface ISticky extends React.HTMLAttributes<HTMLDivElement> {
    variant?: StickyVariant
    ref: any
}

const Sticky: React.FC<ISticky> = forwardRef(function Sticky(props: ISticky, ref: React.ForwardedRef<any>) {
    const { children, variant = "background" } = props

    const css: Array<string | undefined> = ['sticky']
    css.push(`sticky--${variant}`)

    return (
        <div className={css.filter(Boolean).join(" ")} ref={ref}>
            {children}
        </div>
    )
})

export default Sticky