// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding {
  display: flex;
  flex-direction: column;
}
.onboarding__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  padding-top: 0.875rem;
  animation: opacity 400ms forwards;
}
.onboarding__text {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
}
.onboarding__tips {
  display: flex;
  flex-direction: column;
  grid-gap: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Onboarding.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,qBAAA;EACA,iCAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AAHJ","sourcesContent":["@import 'src/scss/variables';\n\n.onboarding {\n  display: flex;\n  flex-direction: column;\n\n  &__content {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 2rem;\n    padding-top: 0.875rem;\n    animation: opacity 400ms forwards;\n  }\n\n  &__text {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 12px;\n  }\n\n  &__tips {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
