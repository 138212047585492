// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index {
  background-color: #000;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #000;
}
.index__logo {
  padding-top: 8px;
}
.index__hflex {
  width: auto !important;
  grid-gap: 7px;
}
.index__hflex p {
  font-size: 10px;
}

.main__grid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, calc(50% - 4px));
}

.main__grid div {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/Index/Index.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,sBAAA;EACA,aAAA;AAFJ;AAII;EACE,eAAA;AAFN;;AAOA;EACE,aAAA;EACA,aAAA;EACA,iDAAA;AAJF;;AAOA;EACE,gBAAA;AAJF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.index {\n  background-color: #000;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  color: #000;\n\n  &__logo {\n    padding-top: 8px;\n  }\n\n  &__hflex {\n    width: auto !important;\n    grid-gap: 7px;\n\n    p {\n      font-size: 10px;\n    }\n  }\n}\n\n.main__grid {\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: repeat(2, calc(50% - 4px));\n}\n\n.main__grid div {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
