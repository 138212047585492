// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  display: flex;
  width: 100%;
  height: 6px;
  grid-gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/ProgressBar.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;AADF","sourcesContent":["@import 'src/scss/variables.scss';\n\n.progress-bar {\n  display: flex;\n  width: 100%;\n  height: 6px;\n  grid-gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
