import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColorType } from "../types";
import React from 'react'

export interface IModalReducer {
    modal: {
        isOpen: boolean
        color: ColorType
        layout: React.ReactNode
    }
}

const initialState: IModalReducer = {
    modal: {
        isOpen: false,
        color: "white",
        layout: null
    }
}

export const modalReducer = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setModalOptions(state, action: PayloadAction<IModalReducer>) {
            state.modal = action.payload.modal
        },
        closeModal(state) {
            state.modal = initialState.modal
        }
    },
})

export const {
    setModalOptions,
    closeModal
} = modalReducer.actions;

export default modalReducer.reducer;
