// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-document__chipfield {
  align-self: flex-start;
}
.download-document__chipfield .hflex {
  grid-gap: 6px;
}
.download-document__hflex {
  grid-gap: 4px;
}
.download-document__p {
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/DownloadDocument/DownloadDocument.scss"],"names":[],"mappings":"AAGE;EACE,sBAAA;AAFJ;AAII;EACE,aAAA;AAFN;AAME;EACE,aAAA;AAJJ;AAOE;EACE,cAAA;AALJ","sourcesContent":["@import 'src/scss/variables';\n\n.download-document {\n  &__chipfield {\n    align-self: flex-start;\n\n    .hflex {\n      grid-gap: 6px;\n    }\n  }\n\n  &__hflex {\n    grid-gap: 4px;\n  }\n\n  &__p {\n    line-height: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
