import React, { useEffect, useState } from 'react'
import './Expert.scss'
import Layout from "../../hoc/Layout/Layout";
import Button, { IButton } from "../../components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetExpert } from "../../hooks";
import Card from "../../components/Card/Card";
import Heading from "../../components/Heading/Heading";
import P from "../../components/P/P";
import ChipField from "../../components/ChipField/ChipField";
import VFlex from "../../components/VFlex/VFlex";
import { CardContent } from "../../types";
import { SwiperSlide } from "swiper/react";

import HFlex from "../../components/HFlex/HFlex";
import { formatDate, runTypograph } from "../../helpers";
import Slider from "../../components/Slider/Slider";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import ExpertCard from "../../components/ExpertCard/ExpertCard";
import _ from 'lodash'
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { setRecommendations } from "../../reducers/recommendationsReducer";
import { n8nProvider } from "../../api";

const Expert = () => {
    const { recommendations } = useSelector((state: RootState) => state.recommendations)
    const { images } = useSelector((state: RootState) => state.images)
    const { experts } = useSelector((state: RootState) => state.experts)
    const { user } = useSelector((state: RootState) => state.user)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { expert } = useGetExpert()

    const [isAvailableToAsk, setIsAvailableToAsk] = useState<boolean>()
    const [recommendation, setRecommendation] = useState<any[]>([])

    const questions = user?.attributes?.questions?.data

    const questionsAmount = questions?.filter((question: any) => {
        return expert?.id === question?.attributes?.expert?.data?.id
    })?.length

    useEffect(() => {
        const day = 1000 * 60 * 60 * 24
        setIsAvailableToAsk(questionsAmount <= 3 && (Date.parse(expert?.attributes?.liveDate) - Date.now()) > day)
    }, [expert])


    useEffect(() => {
        setRecommendation(
            _.take(_.shuffle(experts?.filter((item) => {
                return item?.id !== expert?.id
            })), 3)
        )
    }, [location, expert])

    const handleClick = async () => {
        if (recommendations[expert?.id]) return
        await n8nProvider.get(`?action=send-recommendations&expertId=${expert?.id}&chatId=${user?.attributes?.chatId}`)
        dispatch(setRecommendations(expert?.id))
    }

    const buttons: Array<IButton | null> = [
        isAvailableToAsk &&
        questionsAmount < 3 ?
            {
                children: "Задать вопрос",
                variant: "secondary",
                onClick: () => navigate(`/ask-question/${expert?.id}`)
            }
            : null
    ]

    return (
        <Layout
            buttons={buttons.filter(Boolean) as IButton []}
            stickyVariant={"expert"}
            containerVariant={isAvailableToAsk ? 'with-button' : "without-button"}
        >
            <BackButton onClick={() => navigate("/experts")} />
            <div
                className={"expert"}
                key={expert?.id}
            >
                <Card
                    color={"grey"}
                >
                    <VFlex>
                        <div className={"expert__image"}>
                            <img
                                className={"expert__photo"}
                                src={images[`experts-${expert?.id}-expertImage`]}
                                alt=""
                            />
                            <img
                                className={`expert__logo  ${expert?.id === 5 ? "expert__logo--severstal" : ""}`}
                                src={images[`experts-${expert?.id}-companyLogo`]}
                                alt=""
                            />
                        </div>
                        <VFlex>
                            <HFlex>
                                <ChipField
                                    color={"purple"}
                                >
                                    <P variant={"int-2"}>
                                        {formatDate(expert?.attributes?.liveDate)}
                                    </P>
                                </ChipField>
                                <ChipField
                                    color={questionsAmount === 3 ? "orange" : "pink"}
                                >
                                    <P variant={"int-2"}>
                                        {questionsAmount}/3 вопросов
                                    </P>
                                </ChipField>
                            </HFlex>
                            <div className={"expert__title"}>
                                <Heading
                                    size={2}
                                    color={"white"}
                                >
                                    {runTypograph(expert?.attributes?.name)}
                                </Heading>
                                <P color={"weakWhite"}>{runTypograph(expert?.attributes?.jobTitle)}</P>
                            </div>
                            <P color={"white"} className={"expert__bio"}>{runTypograph(expert?.attributes?.bio)}</P>
                        </VFlex>
                    </VFlex>
                </Card>
                <Slider
                    spaceBetween={16}
                    slidesPerView={1.2}
                >
                    {expert?.attributes?.cards?.map((card: CardContent, index: number) => {
                        return (
                            <SwiperSlide
                                key={index}
                            >
                                <Card
                                    color={card?.bg_color}
                                    className={"expert__card"}
                                >
                                    <VFlex>
                                        <ChipField
                                            color={"black"}
                                        >
                                            <P variant={"int-2"}
                                               color={"white"}
                                            >
                                                {runTypograph(card?.tag)}
                                            </P>
                                        </ChipField>
                                        <Heading size={3} color={"black"}>
                                            {runTypograph(card?.description)}
                                        </Heading>
                                    </VFlex>
                                </Card>
                            </SwiperSlide>
                        )
                    })}
                </Slider>
                <Card
                    color={"grey"}
                >
                    <VFlex>
                        <Heading
                            size={2}
                            color={"white"}
                        >
                            Любимый мем
                        </Heading>
                        <img
                            className="expert__meme"
                            src={images[`experts-${expert?.id}-memeImage`]}
                            alt=""
                        />
                    </VFlex>
                </Card>
                <VFlex>
                    {expert?.attributes?.reasons?.map((reason: CardContent, index: number) => {
                        return (
                            <Card
                                key={index}
                                color={reason?.bg_color}
                            >
                                <VFlex>
                                    <Heading size={2} color={"white"}>
                                        {runTypograph(reason?.tag)}
                                    </Heading>
                                    <P
                                        color={"white"}
                                    >
                                        {runTypograph(reason.description)}
                                    </P>
                                </VFlex>
                            </Card>
                        )
                    })}
                </VFlex>
                <VFlex>
                    <Card
                        color={"grey"}
                    >
                        <VFlex>
                            <Heading
                                size={2}
                                color={"white"}
                            >
                                {runTypograph(expert?.attributes?.recommendation?.tag)}
                            </Heading>
                            {
                                expert?.id !== 9 ?
                                    (expert?.attributes?.recommendation?.text ?
                                        <P
                                            color={"white"}
                                        >
                                            {runTypograph(expert?.attributes?.recommendation?.text)}
                                        </P>
                                        : null)
                                    :
                                    <P
                                        color={"white"}
                                    >
                                        Семинар Маршалла Розенберга
                                        <br />
                                        «Ненасильственное общение»
                                    </P>
                            }
                            {
                                expert?.attributes?.recommendation?.list?.length ?
                                    <ul>
                                        {expert?.attributes?.recommendation?.list.map((item: any, index: number) => {
                                            return <li key={index}>
                                                <P
                                                    color={"white"}
                                                >
                                                    {runTypograph(item)}
                                                </P>
                                            </li>
                                        })}
                                    </ul>
                                    : null
                            }
                            <VFlex variant={"small-gap"}>
                                <Button
                                    variant={recommendations[expert?.id] ? "secondary" : "primary"}
                                    icon={
                                        recommendations[expert?.id] ?
                                            <img src={images["icon-correct"]} alt="" />
                                            :
                                            <img src={images["icon-plus"]} alt="" />
                                    }
                                    onClick={handleClick}
                                >
                                    {recommendations[expert?.id] ? "Отправили!" : "Отправьте мне"}
                                </Button>
                                <P
                                    variant={"int-2"}
                                    color={"weakWhite"}
                                    className={"expert__recommendation"}
                                >
                                    Пришлём подборку в бот
                                </P>
                            </VFlex>
                        </VFlex>
                    </Card>
                </VFlex>
                <VFlex
                    className={"expert__more"}
                    variant={"10px"}
                >
                    <Heading
                        size={2}
                        color={"white"}
                        className={"expert__more-title"}
                    >
                        Другие эксперты
                    </Heading>
                    {
                        recommendation?.map((expert, index) => {
                            return (
                                <Card
                                    key={index}
                                    color={"grey"}
                                    onClick={() => {
                                        navigate(`/experts/${expert.id}`)
                                    }}
                                >
                                    <ExpertCard expert={expert} />
                                </Card>
                            )
                        })
                    }
                    <Card
                        color={"grey"}
                    >
                        <P
                            color={"white"}
                        >
                            {runTypograph("Задай вопросы и следи за календарём — в дни ответов эксперты выберут лучшие, а их авторы получат подарки")}
                        </P>
                    </Card>
                </VFlex>
            </div>
        </Layout>
    )
}

export default Expert